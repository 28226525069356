<template>
    <div id="contact_form">
        <div class="title_block" :class="[{stop:ifTitleStop}]" ref="title_block">
            <div class="w1200">
                <h2 class="txt-bold">
                    Let’s talk<br>about <span>your</span><br><span>project</span>
                </h2>
                <p>
                    選擇怎漾！<br>開始不一樣！
                </p>
            </div>
        </div>
        <div class="inner" ref="inner">
            <div class="w1200">
                <form class="col50" method="post" @submit.prevent="submitForm">
                    <div class="form_item required">
                        <p>姓名</p>
                        <input type="text" name="name" id="" class="form_i_100" required v-model="formData.name">  
                    </div>
                    <div class="form_item required">
                        <p>職稱</p>
                        <input type="text" name="title" id="" class="form_i_100" required v-model="formData.title">  
                    </div>
                    <div class="form_item required">
                        <p>電子郵件信箱</p>
                        <input type="email" name="email" id="" class="form_i_100" required v-model="formData.email">  
                    </div>
                    <div class="form_item required">
                        <p>聯絡電話</p>
                        <input type="text" name="phone" id="" class="form_i_100" required v-model="formData.phone">  
                    </div>
                    <div class="form_item required">
                        <p>公司名稱</p>
                        <input type="text" name="company_name" id="" class="form_i_100" required v-model="formData.company_name">
                    </div>
                    <div class="form_item required address_block">
                        <p>公司地址</p>
                        <div class="inputblock">
                            <select name="city_county" id="" class="form_i_cus" required v-model="formData.city_county">
                                <option value="">縣市</option>
                                <option :value="item.CityName" v-for="(item, index) in countyData">{{item.CityName}}</option>
                            </select>
                            <select name="district" id="" class="form_i_cus" required v-model="formData.district">
                                <option value="">區域</option>
                                <option :value="item.AreaName" v-for="(item, index) in areaData">{{item.AreaName}}</option>
                            </select>
                            <input type="text" name="address_line" id="" class="form_i_other" required v-model="formData.address_line">   
                        </div>
                    </div>
                    <div class="form_item required">
                        <p>預算</p>
                        <select name="budget" id="" class="form_i_100" required v-model="formData.budget">
                            <option value=""></option>
                            <option value="100萬以下">100萬以下</option>
                            <option value="100-200萬">100-200萬</option>
                            <option value="200-300萬">200-300萬</option>
                            <option value="300-400萬">300-400萬</option>
                            <option value="400-500萬">400-500萬</option>
                            <option value="500萬以上">500萬以上</option>
                        </select>
                    </div>
                    <div class="form_item">
                        <p>需要服務的產品品牌</p>
                        <input type="text" name="brand" id="" class="form_i_100" v-model="formData.brand">
                    </div>
                    <div class="form_item service_block required">
                        <p>想洽詢的服務（可複選）</p>
                        <div class="inputblock">
                            <input type="checkbox" name="service_types" id="brand" value="品牌策劃" v-model="service_types_origin" required>
                            <label for="brand" class="form_i_50"><span></span>品牌策劃</label> 
                            <input type="checkbox" name="service_types" id="development" value="軟體開發" v-model="service_types_origin">
                            <label for="development" class="form_i_50"><span></span>軟體開發</label>
                            <input type="checkbox" name="service_types" id="design" value="創意設計" v-model="service_types_origin">
                            <label for="design" class="form_i_50"><span></span>創意設計</label>
                            <input type="checkbox" name="service_types" id="event" value="活動禮贈品規劃" v-model="service_types_origin">
                            <label for="event" class="form_i_50"><span></span>活動禮贈品規劃</label>
                            <input type="checkbox" name="service_types" id="other" value="其他" v-model="service_types_origin">
                            <label for="other" class="form_i_50"><span></span>其他</label>
                        </div>
                    </div>
                    <div class="form_item required">
                        <p>請簡述想要您的需要的服務內容、預期完成時間…等 (字數上限：2,000字)</p>
                        <textarea name="description" id="" cols="30" rows="5" class="form_i_100" required v-model="formData.description"></textarea>
                    </div>
                    <button type="button" class="submit_form" @click="submitForm();" :disabled="ifSubmitDisable">
                        <img src="@/assets/img/index/arrow.svg" alt="">
                        <h6 class="txt-bold">
                            Submit
                        </h6>
                    </button>
                </form> 
            </div>
        </div>
    </div>
</template>

<script>
import { ValidationProvider } from 'vee-validate';
import qs from 'qs';
export default {
    name: 'contactForm',
    components: {
        ValidationProvider
    },
    data: function(){
        return {
            formData: {
                name: '',
                title: '',
                email: '',
                phone: '',
                company_name: '',
                city_county: '',
                district: '',
                address_line: '',
                budget: '',
                brand: '',
                service_types: '',
                description: '',
            },
            countyData: {},
            areaData: {},
            service_types_origin: [],
            scrollDistence: window.scrollY,
            ifTitleStop: false,
            ifSubmitDisable: false,
        }
    },
    methods: {
        listenScrollDistence: function(){ 
            this.scrollDistence = window.scrollY;
        },
        getCounty: function(){
            this.axios({
                method: 'get',
                url: 'https://work.howdesign.com.tw/_API/howgroup/CityCountyData.json',
                'Content-Type': 'application/json',
            })
            .then((result) => { 
                this.countyData = result.data
            })
            .catch((err) => { console.error(err) })
        },
        submitForm: function(){
            var submitData = this.formData; 
            console.log(submitData.description.length);

            if(!submitData.name){
                this.popLightbox('error', '請填寫姓名')
            } else if(!submitData.title){
                this.popLightbox('error', '請填寫職稱')
            } else if(!submitData.email){
                this.popLightbox('error', '請填寫電子郵件信箱')
            } else if(!submitData.phone){
                this.popLightbox('error', '請填寫聯絡電話')
            } else if(!submitData.company_name){
                this.popLightbox('error', '請填寫公司名稱')
            } else if(!submitData.city_county || !submitData.district || !submitData.address_line){
                this.popLightbox('error', '請填寫完整公司地址')
            } else if(!submitData.budget){
                this.popLightbox('error', '請填寫預算')
            } else if(!submitData.service_types){
                this.popLightbox('error', '請填寫想洽詢的服務')
            } else if(!submitData.description){
                this.popLightbox('error', '請填寫您的需要的服務內容')
            } else if(submitData.description.length > 2000){
                this.popLightbox('error', '服務內容限制2000字以內')
            } else {
                this.ifSubmitDisable = true;
                this.$http.post(process.env.VUE_APP_API_KEY+'/contact', submitData).then((response) => {
                    // success callback
                    console.log('success');

                    window.dataLayer.push({
                        'event': 'user_submit_contact'
                    });

                    this.ifSubmitDisable = false;
                    this.$swal.fire({
                        position: 'center-center',
                        icon: 'success',
                        title: '已收到您的資訊',
                        text: '我們將盡快與您聯繫',
                        confirmButtonText: '確定',
                        showClass: {
                            popup: 'animated fadeInDown faster'
                        },
                        hideClass: {
                            popup: 'animated fadeOutUp faster'
                        }
                    }).then(result => {
                        this.$router.go(0);
                    });
                }, (response) => {
                    // error callback
                    console.log('error');
                });
                this.$http.post(process.env.VUE_APP_API_URL+'/contacts/tmp', qs.stringify(submitData));
            }
        },
        popLightbox: function(icon, title, text){
            this.$swal.fire({
                position: 'center-center',
                icon: icon,
                title: title,
                text: text,
                confirmButtonText: '確定',
                showClass: {
                    popup: 'animated fadeInDown faster'
                },
                hideClass: {
                    popup: 'animated fadeOutUp faster'
                }
            })
        }
    },
    watch: {
        service_types_origin: function(){
            this.formData.service_types = this.service_types_origin.toString();
        },
        scrollDistence: function(){
            if(this.scrollDistence + this.$refs.title_block.offsetHeight >= this.$refs.inner.offsetHeight){
                this.ifTitleStop = true;
            }else{
                this.ifTitleStop = false;
            }
        },
        'formData.city_county': function(){
            this.formData.district='';
            for(var i=0; i<this.countyData.length; i++){
                if(this.formData.city_county == this.countyData[i].CityName){
                    this.areaData = this.countyData[i].AreaList;
                }
                if(this.formData.city_county == ''){
                    this.areaData = {};
                }
            }
        }
    },
    created: function(){
        window.addEventListener('scroll', this.listenScrollDistence, true);
        this.getCounty();
    },
    destroyed: function(){
        window.removeEventListener('scroll', this.listenScrollDistence, true);
    }
}
</script>

<style scoped lang="scss">
#contact_form{
    // select{
    //     background: #F4F4F4 url("~@/assets/img/select_down.png") no-repeat calc(100% - 10px) center;
    // }
    position: relative;
    .inner{
        padding: 10% 0;
        min-height: 500px;
        text-align: right;
        position: relative;
        z-index: 2;
        form{
            text-align: left;
            .form_item{
                margin-bottom: 20px;
                display: flex;
                flex-wrap: wrap;
                p{
                    width: 100%;
                    position: relative;
                }
                .form_i_100{width: 100%;}
                .inputblock{
                    width: calc(100% + 10px);
                    margin: 0 -5px;
                    display: flex;
                    flex-wrap: wrap;
                    .form_i_10,
                    .form_i_15,
                    .form_i_50,
                    .form_i_70,
                    .form_i_80,
                    .form_i_cus,
                    .form_i_other{
                        margin: 5px;
                    }
                    .form_i_10{width: calc(10% - 10px);}
                    .form_i_15{width: calc(15% - 10px);}
                    .form_i_50{width: calc(50% - 10px);}
                    .form_i_70{width: calc(70% - 10px);}
                    .form_i_80{width: calc(80% - 10px);}
                }
                &.address_block{
                    .inputblock{
                        .form_i_cus{width: 90px;}
                        .form_i_other{flex: 1;}
                    }
                }
                &.required{
                    p::before{
                        content: '*';
                        color: #f00;
                    }
                }
            }
            .submit_form{
                margin-top: 40px;
                &:hover{
                    img{
                        animation: arrow_h 1s linear infinite;
                    }
                }
                img{
                    margin-right: 20px;
                }
                img, h6{
                    display: inline-block;
                    vertical-align: middle;
                }
            }
        }
    }
    .title_block{
        width: 100%;
        padding: 10% 0;
        position: fixed;
        top: 0;
        &.stop{
            position: absolute;
            bottom: 0;
            top: auto;
            left: 0;
        }
        h2{
            margin: 0 0 30px;
            text-transform: uppercase;
            span{
                position: relative;
                    &::before{
                    content: '';
                    width: 100%;
                    height: 50%;
                    position: absolute;
                    bottom: -10%;
                    left: -5%;
                    background-color: $color-yellow;
                    opacity: .8;
                    z-index: -1;
                    transform: scaleX(1);
                    transform-origin: left;
                    transition: .5s 2s;
                }
                &.active{
                    &::before{
                        content: '';
                        transform: scaleX(1);
                    }
                }
            }
        }
        p{
            line-height: 2;
        }
    }
}
@media screen and (max-width: 1023px){
    #contact_form{
        .title_block{
            padding: 10% 0 0;
            position: relative;
            text-align: center;
            &.stop{
                position: relative;
            }
        }
        .inner{
            form{
                width: 100%;
            }
        }
    }
}
@media screen and (max-width: 768px){
    #contact_form{
        .title_block{
           padding: 130px 0 80px; 
        }
        .inner{
            padding: 0 0 10%;
        }
    }
}
@media screen and (max-width: 425px){
    #contact_form{
        .inner{
            padding: 0 0 10%;
            form{
                .form_item{
                    &.address_block{
                        .inputblock{
                            .form_i_cus{width: calc(50% - 10px);}
                            .form_i_other{flex: auto; width: 100%;}
                        }
                    }
                    &.service_block{
                        .inputblock{
                            .form_i_50{flex: auto; width: 100%;}
                        }
                    }
                }
            }
        }
    }
}

</style>
