<template>
    <div class="page">
        <publicHeader/>
        <contactForm/>
    </div>
</template>

<script>
import publicHeader from '@/components/public/Header.vue'
import contactForm from '@/components/page/contact/contactForm.vue'
export default {
    name: 'Home',
    components: {
        publicHeader,
        contactForm
    }
}
</script>

<style scoped lang="scss">
    .page{
        position: relative;
    }
</style>
